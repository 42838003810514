import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { getBaseURL } from "./../../../helpers/utils";
import userCountries from "./../../../helpers/userCountries";
// import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import LogoModal from "../../../components/LogoModal";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import moment from "moment";
// import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
// const apiurl = `https://api.devsunfifafever.sunkonnect.in/`;
const apiurl = `${getBaseURL()}/`;
const Link4 = () => {
  const [countryLogo, setCountryLogo] = useState(null);
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  // const dispatch = useDispatch();
  const [doc, setDoc] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const { id } = useParams();
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");

  let todayDates = moment(new Date()).format("YYYYMMDD");
  let yestDates = moment(new Date()).subtract(1, "days").format("YYYYMMDD");
  let tomoDates = moment(new Date()).add(1, "days").format("YYYYMMDD");
  let newConcatString = yestDates + "-" + tomoDates + "-" + todayDates;
  let base64String = window.btoa(newConcatString);

  const getDocData = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}doctors/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      if (response.data.status === 400) {
        setDoc(null);
      } else {
        setDoc(response.data.doctor);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  const getDocPrediction = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}predictions/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      setPredictions(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  useEffect(() => {
    if (id) {
      getDocData(id);
      getDocPrediction(id);
    }
  }, [id, getDocData, getDocPrediction]);

  useEffect(() => {
    if (doc) {
      const clogo = userCountries.find((i) => i.code === doc.country);
      setCountryLogo(clogo.logo);
    }
  }, [doc]);

  const handleChange = (e) => {
    if (e.target.name === "match-1") {
      setTeam1(e.target.value);
    }
    if (e.target.name === "match-2") {
      setTeam2(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiurl}predictions/${predictions[4].round}/${id}`,
        {
          round: predictions[3].round,
          doctor_id: doc.doctor_id,
          countries: [team1, team2],
        },
        {
          headers: {
            AuthKey: base64String,
          },
        }
      );
      if (response.data.status === 200) {
        setModalShow(true);
        audioSuccess.play();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (!doc) {
    return (
      <div className="bg-white p-4 rounded">No doctor found or wrong link!</div>
    );
  } else if (predictions && predictions[4].matches.length > 0) {
    return (
      <>
        {doc && predictions && (
          <div className="h-full w-full bg-orange-50/30 pb-14 min-h-screen">
            <div className="max-w-md w-full mx-auto relative">
              <SubHeader title={`Dr. ${doc.name}`} />
              <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
                <div>
                  <span className="font-bold text-base">
                    {predictions[4].name} :
                  </span>{" "}
                  {predictions[4].description}
                </div>
                {(predictions[4].is_predicted || predictions[4].is_locked) && (
                  <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                    <span className="font-bold text-base">
                      {predictions[4].is_predicted &&
                        "You have already predicted this round."}
                      {predictions[4].is_locked &&
                        "This round has been closed."}
                    </span>
                  </div>
                )}
              </div>
              <div className="pb-2 mx-4 flex flex-col gap-4 mt-4">
                {Object.keys(predictions[4].matches).map((key, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-4 items-center rounded"
                    >
                      <h1 className="font-bold">Semi Final - {index + 1}</h1>
                      <div className="flex gap-2 items-center justify-between w-full relative">
                        <div className="bg-orange text-white rounded-full absolute w-8 h-8 z-[1] flex items-center justify-center border shadow-md left-0 right-0 top-0 bottom-0 mx-auto my-auto">
                          vs
                        </div>
                        {predictions[4].matches[key].map((team, i) => {
                          return (
                            <label
                              key={i}
                              htmlFor={team.name}
                              className="customRadio relative w-full shadow-lg"
                            >
                              {team.admin_selected && (
                                <div className="bg-white border border-slate-400 shadow rounded-full w-5 h-5 left-0 right-0 mx-auto -bottom-2 absolute z-[1]">
                                  <MdCheckCircle className="w-full h-full  text-green-500 " />
                                </div>
                              )}
                              {predictions[4].is_predicted ||
                              predictions[4].is_locked ? (
                                <input
                                  type="radio"
                                  id={team.name}
                                  name={`match-${index + 1}`}
                                  value={team.code}
                                  className="absolute -left-[9999px]"
                                  onChange={(e) => handleChange(e)}
                                  checked={team.selected}
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id={team.name}
                                  name={`match-${index + 1}`}
                                  value={team.code}
                                  className="absolute -left-[9999px]"
                                  onChange={(e) => handleChange(e)}
                                />
                              )}
                              <div className="bg-white rounded pt-9 pb-5  px-4 w-full relative flex items-center justify-center font-bold text-lg">
                                {team.country}
                                <img
                                  src={team.image}
                                  alt={team.country}
                                  className="absolute -top-1/4"
                                />
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              {!predictions[4].is_predicted && !predictions[4].is_locked && (
                <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
                  <div className="max-w-md w-full mx-auto relative px-3 py-2">
                    <button
                      type="button"
                      className="btn w-full text-center"
                      disabled={!team1 || !team2 ? true : false}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {logoShow && predictions && (
          <LogoModal setLogoShow={setLogoShow} countryLogo={countryLogo}>
            <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
              <div>
                <span className="font-bold text-base">
                  {predictions[4]?.name} :
                </span>{" "}
                {predictions[4]?.description}
              </div>
              {(predictions[4]?.is_predicted || predictions[4]?.is_locked) && (
                <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                  <span className="font-bold text-base">
                    {predictions[4]?.is_predicted &&
                      "You have already predicted this round."}
                    {predictions[4]?.is_locked && "This round has been closed."}
                  </span>
                </div>
              )}
            </div>
          </LogoModal>
        )}

        {modalShow && predictions && (
          <AnimationModal setModalShow={setModalShow}>
            <SuccessfulSubmit
              title={`${predictions[4].name} prediction submitted successfully!`}
            />
          </AnimationModal>
        )}
      </>
    );
  } else {
    return (
      <div className="bg-white p-4 rounded">
        Waiting for {predictions && predictions[2].name} results
      </div>
    );
  }
};

export default Link4;
