import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ClientHeader from "../components/ClientHeader";
import Drawer from "../components/Drawer";
const ClientLayout = () => {
  const { drawer } = useSelector((state) => state.layout);
  return (
    <>
      <div className="h-full w-full bg-orange-50/30 pb-14 min-h-screen">
        <ClientHeader />
        <Drawer drawer={drawer} />
        <div className="max-w-md w-full mx-auto relative">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ClientLayout;
