import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdShare } from "react-icons/md";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import { setGoldenBootPrediction } from "../../../redux/features/predictions/predictionsSlice";
// import { toast } from "react-toastify";
import LogoModal from "../../../components/LogoModal";
import { externalLink } from "./../../../helpers/utils";

const Round6 = () => {
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [goldPlayer, setGoldPlayer] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState(null);
  const { userDoctors } = useSelector((state) => state.auth);
  const { predictions, goldenBootPlayers } = useSelector(
    (state) => state.predictions
  );
  const { id } = useParams();

  // const [allTeams, setAllTeams] = useState([]);

  useEffect(() => {
    if (!goldenBootPlayers.length) {
      navigate("/");
    }
  }, [navigate, goldenBootPlayers.length]);

  useEffect(() => {
    if (userDoctors?.length > 0 && id) {
      let newDoc = userDoctors.find(
        (d) => d.doctor_id.toString() === id.toString()
      );
      setDoc(newDoc);
    } else {
      navigate("/");
    }
  }, [userDoctors, id, navigate]);
  const handleChange = (e) => {
    setGoldPlayer(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(goldPlayer);
    dispatch(
      setGoldenBootPrediction({
        doctor_id: doc.doctor_id,
        player_name: goldPlayer,
      })
    );
    setModalShow(true);
    audioSuccess.play();
  };

  const shareData = {
    title: "Sun Fifa Fever",
    // text: `Sun Fifa Fever : Select Golden Boot Player`,
    text: `Sun Fifa Fever :${predictions[3]?.name}`,
    // url: `${externalLink}/link/goldenBoot/${doc?.doctor_id}`,
    url: `${externalLink()}/link/${predictions[3]?.round}/${doc?.doctor_id}`,
  };

  const shareLink = async () => {
    console.log(shareData.url);
    try {
      await navigator.share(shareData);
      alert("Link shared successfully");
    } catch (err) {
      console.log(`Error: ${err}`);
      alert(`Your browser is not supported the share feature`);
    }
  };
  console.log(goldPlayer);

  return (
    <>
      {doc && (
        <>
          <SubHeader title={`Dr. ${doc.name}`} />
          <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
            <div>
              <span className="font-bold text-base">
                Select Golden Boot Player :
              </span>{" "}
              Choose one player from the list mentioned below and submit
            </div>
            <div>
              <button
                className="btn !py-1 !px-2 !rounded flex gap-2 items-center !bg-red-600"
                onClick={shareLink}
              >
                <MdShare />
                Share link with doctor
              </button>
            </div>
            {goldenBootPlayers?.some(
              (item) => item.admin_selected === true
            ) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[3].is_predicted &&
                    "You have already predicted this round."}
                  {goldenBootPlayers?.some(
                    (item) => item.admin_selected === true
                  ) && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
          <div className="mx-4 flex flex-col bg-white rounded-md overflow-hidden shadow">
            {goldenBootPlayers.length > 0 &&
              !predictions[3].doctor_selected_player_name &&
              goldenBootPlayers.map((player, index) => {
                return (
                  <label
                    key={index}
                    className={`px-6 py-2 w-full block shadow border-b border-slate-100 ${
                      player.admin_selected
                        ? "bg-green-300 drop-shadow-lg"
                        : "bg-white"
                    }`}
                    htmlFor={player.player_name}
                    data-id={player.player_name}
                  >
                    <div className=" flex items-center gap-4">
                      <input
                        type="radio"
                        id={player.player_name}
                        value={player.player_name}
                        name="goldenBoot"
                        onChange={(e) => handleChange(e)}
                        // checked={
                        //   predictions[3].doctor_selected_player_name ===
                        //   player.player_name
                        // }
                        disabled={
                          predictions[3].doctor_selected_player_name ===
                          player.player_name
                        }
                      />
                      {player.player_name}
                    </div>
                  </label>
                );
              })}

            {goldenBootPlayers.length > 0 &&
              predictions[3].doctor_selected_player_name &&
              goldenBootPlayers.map((player, index) => {
                return (
                  <label
                    key={index}
                    className={`px-6 py-2 w-full block shadow border-b border-slate-100 ${
                      player.admin_selected
                        ? "bg-green-300 drop-shadow-lg"
                        : "bg-white"
                    }`}
                    htmlFor={player.player_name}
                    data-id={player.player_name}
                  >
                    <div className=" flex items-center gap-4">
                      <input
                        type="radio"
                        id={player.player_name}
                        value={player.player_name}
                        name="goldenBoot"
                        onChange={(e) => handleChange(e)}
                        checked={
                          predictions[3].doctor_selected_player_name ===
                          player.player_name
                        }
                        disabled={true}
                      />
                      {player.player_name}
                    </div>
                  </label>
                );
              })}
          </div>
          {!predictions[3].is_predicted && !predictions[3].is_locked && (
            <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
              <div className="max-w-md w-full mx-auto relative px-3 py-2">
                <button
                  type="button"
                  className="btn w-full text-center"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {logoShow && (
        <LogoModal setLogoShow={setLogoShow}>
          <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[3]?.name} :
              </span>{" "}
              {predictions[3]?.description}
            </div>
            {(predictions[3]?.is_predicted || predictions[3]?.is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[3]?.is_predicted &&
                    "You have already predicted this round."}
                  {predictions[3]?.is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
        </LogoModal>
      )}

      {modalShow && (
        <AnimationModal setModalShow={setModalShow} routerPath={-1}>
          <SuccessfulSubmit
            title={`${predictions[3].name} prediction submitted successfully!`}
          />
        </AnimationModal>
      )}
    </>
  );
};

export default Round6;
