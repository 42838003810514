import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AnimationModal = ({
  setModalShow,
  routerPath,
  countryLogo,
  children,
}) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setModalShow(false);
      if (routerPath) {
        navigate(routerPath);
      } else {
        window.location.reload();
      }
    }, 5000);
  }, [navigate, setModalShow, routerPath]);
  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-hidden bg-black/70 z-10 flex items-center justify-center">
      <div className="max-w-md w-full mx-auto relative px-3 py-2">
        <div className="bg-white p-4 relative rounded-xl">
          {countryLogo && (
            <img
              src={`../../${countryLogo} `}
              alt="fifaimg"
              className="w-full"
            />
          )}
          {user && (
            <img
              src={`../../${user.countryLogo} `}
              alt="fifaimg"
              className="w-full"
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AnimationModal;
