import React from "react";
import { MdStars } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const SingleDoctor = ({
  // id,
  doctor_id,
  name,
  email,
  doctor_code,
  points,
  is_in_top_ten,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative w-full">
      <Link
        to={`detail/${doctor_id}`}
        className="bg-white rounded shadow-lg p-3 relative block"
      >
        <h4 className="font-medium text-lg">Dr. {name}</h4>
        <p className="text-slate-700">{email}</p>
        <div className="flex gap-4 items-center mt-1">
          <div className="bg-green-700 text-sm text-white py-1 px-2 rounded">
            {t("doctor_list.Points")}: {points}
          </div>
          <p className="text-slate-500 text-sm">Code: {doctor_code}</p>
        </div>
      </Link>
      {is_in_top_ten && (
        <Link
          to="leaderboard"
          className="absolute right-3 text-4xl text-[#FFD700] z-[1] top-3"
        >
          <MdStars />
        </Link>
      )}
    </div>
  );
};

export default SingleDoctor;
