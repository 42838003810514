import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getGoldenBootPlayers } from "./redux/features/predictions/predictionsSlice";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "fr", "es"],
    fallbackLng: "en",
    debug: false,
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
);

const user = JSON.parse(localStorage.getItem("fifaAuth"));
if (user) {
  store.dispatch(getGoldenBootPlayers());
}

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <BrowserRouter>
      <Provider store={store}>
        <ToastContainer theme="colored" position="top-center" />
        <App />
      </Provider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
