import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { getBaseURL } from "./../../../helpers/utils";
import userCountries from "./../../../helpers/userCountries";
// import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LogoModal from "../../../components/LogoModal";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
// import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
import { toast } from "react-toastify";
import moment from "moment";
// const apiurl = `https://api.devsunfifafever.sunkonnect.in/`;
const apiurl = `${getBaseURL()}/`;
const Link1 = () => {
  const [countryLogo, setCountryLogo] = useState(null);
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const [doc, setDoc] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const { id } = useParams();
  const [allTeams, setAllTeams] = useState([]);

  let todayDates = moment(new Date()).format("YYYYMMDD");
  let yestDates = moment(new Date()).subtract(1, "days").format("YYYYMMDD");
  let tomoDates = moment(new Date()).add(1, "days").format("YYYYMMDD");
  let newConcatString = yestDates + "-" + tomoDates + "-" + todayDates;
  let base64String = window.btoa(newConcatString);

  const getDocData = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}doctors/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      if (response.data.status === 400) {
        setDoc(null);
      } else {
        setDoc(response.data.doctor);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  const getDocPrediction = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}predictions/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      setPredictions(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  useEffect(() => {
    if (id) {
      getDocData(id);
      getDocPrediction(id);
    }
  }, [id, getDocData, getDocPrediction]);

  useEffect(() => {
    if (doc) {
      const clogo = userCountries.find((i) => i.code === doc.country);
      setCountryLogo(clogo.logo);
    }
  }, [doc]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiurl}predictions/${predictions[0].round}/${id}`,
        {
          round: predictions[0].round,
          doctor_id: doc.doctor_id,
          countries: allTeams,
        },
        {
          headers: {
            AuthKey: base64String,
          },
        }
      );
      if (response.data.status === 200) {
        setModalShow(true);
        audioSuccess.play();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  if (!doc) {
    return (
      <div className="bg-white p-4 rounded">No Doctor found or wrong link!</div>
    );
  } else {
    return (
      <>
        {doc && predictions && (
          <div className="h-full w-full bg-orange-50/30 pb-14 min-h-screen">
            <div className="max-w-md w-full mx-auto relative">
              <SubHeader title={`Dr. ${doc.name}`} />
              <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
                <div>
                  <span className="font-bold text-base">
                    {predictions[0].name} :
                  </span>{" "}
                  {predictions[0].description}
                </div>

                {(predictions[0].is_predicted || predictions[0].is_locked) && (
                  <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                    <span className="font-bold text-base">
                      {predictions[0].is_predicted &&
                        "You have already predicted this round."}
                      {predictions[0].is_locked &&
                        "This round has been closed."}
                    </span>
                  </div>
                )}
              </div>
              <form
                onSubmit={handleSubmit}
                className="mx-4 flex flex-col gap-2"
              >
                {predictions[0].teams.length > 0 &&
                  predictions[0].teams.map((team, index) => {
                    return (
                      <SingleGroup
                        key={index}
                        data={team}
                        predictions={predictions}
                        setAllTeams={setAllTeams}
                        allTeams={allTeams}
                      />
                    );
                  })}
              </form>
              {!predictions[0].is_predicted && !predictions[0].is_locked && (
                <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
                  <div className="max-w-md w-full mx-auto relative px-3 py-2">
                    <button
                      type="button"
                      className="btn w-full text-center"
                      disabled={allTeams.length === 16 ? false : true}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {modalShow && predictions && (
          <AnimationModal setModalShow={setModalShow}>
            <SuccessfulSubmit
              title={`${predictions[0].name} prediction submitted successfully!`}
            />
          </AnimationModal>
        )}
        {logoShow && predictions && (
          <LogoModal setLogoShow={setLogoShow} countryLogo={countryLogo}>
            <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
              <div>
                <span className="font-bold text-base">
                  {predictions[0]?.name} :
                </span>{" "}
                {predictions[0]?.description}
              </div>
              {(predictions[0]?.is_predicted || predictions[0]?.is_locked) && (
                <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                  <span className="font-bold text-base">
                    {predictions[0]?.is_predicted &&
                      "You have already predicted this round."}
                    {predictions[0]?.is_locked && "This round has been closed."}
                  </span>
                </div>
              )}
            </div>
          </LogoModal>
        )}
      </>
    );
  }
};

export default Link1;

const SingleGroup = ({ data, setAllTeams, predictions, allTeams }) => {
  const [values, setValues] = useState([]);
  const handleChange = (e) => {
    if (e.target.checked) {
      if (values.length < 2) {
        setValues((prev) => [...prev, e.target.value]);
        setAllTeams((prev) => [...prev, e.target.value]);
      } else {
        e.target.checked = false;
        toast.error("You can select only 2 teams from each group.");
      }
    } else {
      let newArr = values.filter((d) => d !== e.target.value);
      setValues(newArr);
      let rm = allTeams.filter((item) => item !== e.target.value);
      setAllTeams(rm);
    }
  };
  return (
    <>
      {predictions && (
        <div className="bg-white rounded-md overflow-hidden shadow">
          <h4 className="py-2 px-4 border-b font-medium leading-4 text-sm">
            Group: {data.group}
          </h4>
          {data.teams.map((team, index) => {
            return (
              <label
                key={index}
                className={`px-6 py-2 w-full block  border-b border-slate-100 ${
                  team.admin_selected
                    ? "bg-green-300 drop-shadow-lg"
                    : "bg-white"
                }`}
                htmlFor={team.name}
              >
                <div className=" flex items-center gap-4">
                  {predictions[0].is_predicted || predictions[0].is_locked ? (
                    <input
                      type="checkbox"
                      id={team.country_code}
                      value={team.country_code}
                      name={team.country_code}
                      onChange={(e) => handleChange(e)}
                      checked={team.selected}
                      disabled
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id={team.country_code}
                      value={team.country_code}
                      name={team.country_code}
                      onChange={(e) => handleChange(e)}
                    />
                  )}

                  <img
                    src={`../../flags/${team.country.replace(/ /g, "")}.png`}
                    alt={team.country}
                    className="w-12"
                  />
                  <p>{team.country}</p>
                </div>
              </label>
            );
          })}
        </div>
      )}
    </>
  );
};
