import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { getBaseURL } from "./../../../helpers/utils";
import userCountries from "./../../../helpers/userCountries";
// import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import { MdCheckCircle } from "react-icons/md";
import LogoModal from "../../../components/LogoModal";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import moment from "moment";
// import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
// const apiurl = `https://api.devsunfifafever.sunkonnect.in/`;
const apiurl = `${getBaseURL()}/`;
const Link6 = () => {
  const [countryLogo, setCountryLogo] = useState(null);
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const [goldPlayer, setGoldPlayer] = useState(null);
  //   const [goldenPredition, setGoldenPredition] = useState("");
  const [goldenBootPlayers, setGoldenBootPlayers] = useState([]);
  const [doc, setDoc] = useState(null);
  const [predictions, setPredictions] = useState(null);
  const { id } = useParams();
  //   const [team1, setTeam1] = useState("");

  let todayDates = moment(new Date()).format("YYYYMMDD");
  let yestDates = moment(new Date()).subtract(1, "days").format("YYYYMMDD");
  let tomoDates = moment(new Date()).add(1, "days").format("YYYYMMDD");
  let newConcatString = yestDates + "-" + tomoDates + "-" + todayDates;
  let base64String = window.btoa(newConcatString);

  const getDocData = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}doctors/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      if (response.data.status === 400) {
        setDoc(null);
      } else {
        setDoc(response.data.doctor);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  const getDocPrediction = useCallback(async () => {
    try {
      const response = await axios.get(`${apiurl}predictions/${id}`, {
        headers: {
          AuthKey: base64String,
        },
      });
      setPredictions(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }, [id, base64String]);

  //   const getGoldenPrediction = useCallback(async () => {
  //     try {
  //       const response = await axios.get(
  //         `${apiurl}predictions/golden_boot/${id}`,
  //         {
  //           headers: {
  //             AuthKey: base64String,
  //           },
  //         }
  //       );
  //     //   setGoldenPredition(response.data.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }, [id, base64String]);

  const getGoldenBootPlayers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${apiurl}predictions/admin/golden_boot_players`,
        {
          headers: {
            AuthKey: base64String,
          },
        }
      );
      setGoldenBootPlayers(response.data.players);
    } catch (error) {
      console.error(error);
    }
  }, [base64String]);

  useEffect(() => {
    getGoldenBootPlayers();
  }, [getGoldenBootPlayers]);

  useEffect(() => {
    if (id) {
      getDocData(id);
      getDocPrediction(id);
      //   getGoldenPrediction(id);
    }
  }, [id, getDocData, getDocPrediction]);

  useEffect(() => {
    if (doc) {
      const clogo = userCountries.find((i) => i.code === doc.country);
      setCountryLogo(clogo.logo);
    }
  }, [doc]);

  const handleChange = (e) => {
    setGoldPlayer(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(goldPlayer);
    // dispatch(
    //   setGoldenBootPrediction({
    //     doctor_id: doc.doctor_id,
    //     player_name: goldPlayer,
    //   })
    // );
    // setModalShow(true);
    // audioSuccess.play();
    try {
      const response = await axios.post(
        `${apiurl}predictions/golden_boot/${id}`,
        {
          player_name: goldPlayer,
        },
        {
          headers: {
            AuthKey: base64String,
          },
        }
      );
      if (response.data.status === 200) {
        setModalShow(true);
        audioSuccess.play();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  //   console.log(doc, predictions);

  if (!doc) {
    return (
      <div className="bg-white p-4 rounded">No doctor found or wrong link!</div>
    );
  } else if (predictions) {
    return (
      <>
        {doc && predictions.length > 0 && (
          <div className="h-full w-full bg-orange-50/30 pb-14 min-h-screen">
            <div className="max-w-md w-full mx-auto relative">
              <SubHeader title={`Dr. ${doc.name}`} />
              <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
                <div>
                  <span className="font-bold text-base">
                    {predictions[3].name} :
                  </span>{" "}
                  {predictions[3].description}
                </div>
                {(predictions[3].is_predicted || predictions[3].is_locked) && (
                  <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                    <span className="font-bold text-base">
                      {predictions[3].is_predicted &&
                        "You have already predicted this round."}
                      {predictions[3].is_locked &&
                        "This round has been closed."}
                    </span>
                  </div>
                )}
              </div>
              <div className="mx-4 flex flex-col bg-white rounded-md overflow-hidden shadow">
                {goldenBootPlayers.length > 0 &&
                  !predictions[3].doctor_selected_player_name &&
                  goldenBootPlayers.map((player, index) => {
                    return (
                      <label
                        key={index}
                        className={`px-6 py-2 w-full block shadow border-b border-slate-100 ${
                          player.admin_selected
                            ? "bg-green-300 drop-shadow-lg"
                            : "bg-white"
                        }`}
                        htmlFor={player.player_name}
                        data-id={player.player_name}
                      >
                        <div className=" flex items-center gap-4">
                          <input
                            type="radio"
                            id={player.player_name}
                            value={player.player_name}
                            name="goldenBoot"
                            onChange={(e) => handleChange(e)}
                            // checked={
                            //   predictions[3].doctor_selected_player_name ===
                            //   player.player_name
                            // }
                            disabled={
                              predictions[3].doctor_selected_player_name ===
                              player.player_name
                            }
                          />
                          {player.player_name}
                        </div>
                      </label>
                    );
                  })}

                {goldenBootPlayers.length > 0 &&
                  predictions[3].doctor_selected_player_name &&
                  goldenBootPlayers.map((player, index) => {
                    return (
                      <label
                        key={index}
                        className={`px-6 py-2 w-full block shadow border-b border-slate-100 ${
                          player.admin_selected
                            ? "bg-green-300 drop-shadow-lg"
                            : "bg-white"
                        }`}
                        htmlFor={player.player_name}
                        data-id={player.player_name}
                      >
                        <div className=" flex items-center gap-4">
                          <input
                            type="radio"
                            id={player.player_name}
                            value={player.player_name}
                            name="goldenBoot"
                            onChange={(e) => handleChange(e)}
                            checked={
                              predictions[3].doctor_selected_player_name ===
                              player.player_name
                            }
                            disabled={true}
                          />
                          {player.player_name}
                        </div>
                      </label>
                    );
                  })}
              </div>
              {!predictions[3].is_predicted && !predictions[3].is_locked && (
                <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
                  <div className="max-w-md w-full mx-auto relative px-3 py-2">
                    <button
                      type="button"
                      className="btn w-full text-center"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {logoShow && predictions && (
          <LogoModal setLogoShow={setLogoShow} countryLogo={countryLogo}>
            <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
              <div>
                <span className="font-bold text-base">
                  {predictions[3]?.name} :
                </span>{" "}
                {predictions[3]?.description}
              </div>
              {(predictions[3]?.is_predicted || predictions[3]?.is_locked) && (
                <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                  <span className="font-bold text-base">
                    {predictions[3]?.is_predicted &&
                      "You have already predicted this round."}
                    {predictions[3]?.is_locked && "This round has been closed."}
                  </span>
                </div>
              )}
            </div>
          </LogoModal>
        )}

        {modalShow && predictions && (
          <AnimationModal setModalShow={setModalShow}>
            <SuccessfulSubmit
              title={`${predictions[3].name} prediction submitted successfully!`}
            />
          </AnimationModal>
        )}
      </>
    );
  } else {
    return (
      <div className="bg-white p-4 rounded">
        Waiting for {predictions && predictions[3].name} results
      </div>
    );
  }
};

export default Link6;
