import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../api";
import { setLogout } from "../auth/authSlice";
const initialState = {
  predictions: [],
  goldenBootPlayers: [],
  loading: false,
  isSuccess: false,
  isFailed: false,
  message: "",
  top10: [],
};

export const getDocPredictions = createAsyncThunk(
  "predictions/getDocPredictions",
  async (doctor_id, { rejectWithValue }) => {
    try {
      const response = await API.getDocPredictions(doctor_id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGoldenBootPrediction = createAsyncThunk(
  "predictions/getGoldenBootPrediction",
  async (doctor_id, { rejectWithValue }) => {
    try {
      const response = await API.getGoldenBootPrediction(doctor_id);
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setGoldenBootPrediction = createAsyncThunk(
  "predictions/setGoldenBootPrediction",
  async ({ doctor_id, player_name }, { rejectWithValue }) => {
    try {
      const response = await API.setGoldenBootPrediction(
        doctor_id,
        player_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const setDocPredictions = createAsyncThunk(
  "predictions/setDocPredictions",
  async ({ round, doctor_id, countries }, { rejectWithValue }) => {
    try {
      const response = await API.setDocPredictions(round, doctor_id, countries);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmpTop10 = createAsyncThunk(
  "predictions/getEmpTop10",
  async (country, { rejectWithValue }) => {
    try {
      const response = await API.getEmpTop10(country);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getGoldenBootPlayers = createAsyncThunk(
  "predictions/getGoldenBootPlayers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.getGoldenBootPlayers();
      // console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const predictionsSlice = createSlice({
  name: "predictions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFailed = false;
      state.isSuccess = false;
      state.message = "";
      state.loading = false;
    },
  },
  extraReducers: {
    [setLogout]: (state) => {
      state.predictions = [];
      state.top10 = [];
    },
    [getDocPredictions.pending]: (state) => {
      state.loading = true;
    },
    [getDocPredictions.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.predictions = action.payload.data;
        state.message = action.payload.message;
      }
    },
    [getDocPredictions.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
    [setDocPredictions]: (state) => {
      state.loading = true;
    },
    [setDocPredictions.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.message = action.payload.message;
      } else {
        state.isFailed = true;
        state.message = action.payload.message;
      }
    },
    [setDocPredictions]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
    [getEmpTop10.pending]: (state) => {
      state.loading = true;
    },

    [getEmpTop10.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.message = action.payload.message;
        state.top10 = action.payload.doctors;
      }
    },
    [getEmpTop10.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
    [getGoldenBootPlayers.pending]: (state) => {
      state.loading = true;
    },
    [getGoldenBootPlayers.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.message = action.payload.message;
        state.goldenBootPlayers = action.payload.players;
      }
    },
    [getGoldenBootPlayers.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
  },
});
export const { clearState } = predictionsSlice.actions;
export default predictionsSlice.reducer;
