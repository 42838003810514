import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader";
import { MdPlaylistAddCheck } from "react-icons/md";
import { useTranslation } from "react-i18next";
import {
  getDocPredictions,
  clearState,
} from "../../redux/features/predictions/predictionsSlice";

const Detail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [doc, setDoc] = useState(null);
  const { id } = useParams();
  const { userDoctors } = useSelector((state) => state.auth);
  const { predictions, isSuccess, isFailed, goldenBootPlayers } = useSelector(
    (state) => state.predictions
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (userDoctors?.length > 0 && id) {
      let newDoc = userDoctors.find(
        (d) => d.doctor_id.toString() === id.toString()
      );
      if (newDoc !== undefined) {
        setDoc(newDoc);
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [userDoctors, id, navigate]);
  useEffect(() => {
    if (id) {
      dispatch(getDocPredictions(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSuccess || isFailed) {
      dispatch(clearState());
    }
  }, [isSuccess, isFailed, dispatch]);

  // console.log(predictions, goldenBootPlayers);

  return (
    <>
      {doc && (
        <>
          <SubHeader title={`Dr. ${doc.name}`} />
          <div className="px-3 py-2 font-medium">
            {t("rounds.Select_Round")}
          </div>

          {predictions.length > 0 && (
            <div className="flex gap-3 flex-col px-3 w-full">
              {/* group match */}
              <div className="w-full relative flex">
                {predictions[0].teams?.length > 0 ||
                predictions[0].is_predicted ||
                predictions[0].is_locked ? (
                  <Link
                    to={`/predictions/group_matches/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round1`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Group_Matches")}
                      </div>
                      {(predictions[0].is_predicted ||
                        predictions[0].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round1`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Group_Matches")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* group of 16 */}
              <div className="w-full relative flex">
                {predictions[1].teams?.length > 0 ||
                predictions[1].is_predicted ||
                predictions[1].is_locked ? (
                  <Link
                    to={`/predictions/round_of_16/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round2`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Round_of_16")}
                      </div>
                      {(predictions[1].is_predicted ||
                        predictions[1].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round2`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Round_of_16")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* quater finals */}
              <div className="w-full relative flex">
                {predictions[2].teams?.length > 0 ||
                predictions[2].is_predicted ||
                predictions[2].is_locked ? (
                  <Link
                    to={`/predictions/quarters/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round3`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Quarter_Finals")}
                      </div>
                      {(predictions[2].is_predicted ||
                        predictions[2].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round3`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Quarter_Finals")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* golden boot */}

              <div className="w-full relative flex">
                {goldenBootPlayers.length > 0 ? (
                  <Link
                    to={`/predictions/golden_boot/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round4`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Golden_Boot")}
                      </div>
                      {(predictions[3].is_predicted ||
                        predictions[3].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round4`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Golden_Boot")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* semi finals */}
              <div className="w-full relative flex">
                {predictions[4].teams?.length > 0 ||
                predictions[4].is_predicted ||
                predictions[4].is_locked ? (
                  <Link
                    to={`/predictions/semifinals/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round5`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Semi_Finals")}
                      </div>
                      {(predictions[4].is_predicted ||
                        predictions[4].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round5`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Semi_Finals")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* finals */}
              <div className="w-full relative flex">
                {predictions[5].teams?.length > 0 ||
                predictions[5].is_predicted ||
                predictions[5].is_locked ? (
                  <Link
                    to={`/predictions/finals/${id}`}
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round6`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Finals")}
                      </div>
                      {(predictions[5].is_predicted ||
                        predictions[5].is_locked) && (
                        <MdPlaylistAddCheck className="text-white text-2xl" />
                      )}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round6`}
                  >
                    <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                      <div className="grow-1 text-xl text-center">
                        {t("rounds.Finals")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* {predictions.map((round, index) => {
                return (
                  <div key={index} className="w-full relative flex">
                    {round?.teams?.length > 0 ||
                    round?.is_predicted ||
                    round?.is_locked ? (
                      <Link
                        to={`/predictions/${round.round}/${id}`}
                        className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round${
                          index + 1
                        }`}
                      >
                        <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                          <div className="grow-1 text-xl text-center">
                            {round.round === "group_matches" &&
                              t("rounds.Group_Matches")}
                            {round.round === "round_of_16" &&
                              t("rounds.Round_of_16")}
                            {round.round === "quarters" &&
                              t("rounds.Quater_Finals")}
                            {round.round === "golden_boot" &&
                              t("rounds.Golden_Boot")}
                            {round.round === "semifinals" &&
                              t("rounds.Semi_Finals")}
                            {round.round === "finals" && t("rounds.Finals")}
                          </div>
                          {(round?.is_predicted || round?.is_locked) && (
                            <MdPlaylistAddCheck className="text-white text-2xl" />
                          )}
                        </div>
                      </Link>
                    ) : (
                      <div
                        className={`bg-[#9a1032] w-full rounded shadow-lg px-6 py-8 bg-round bg-round${
                          index + 1
                        }`}
                      >
                        <div className="relative tracking-wider font-bold flex items-center gap-4 text-white">
                          <div className="grow-1 text-xl text-center">
                            {round.round === "group_matches" &&
                              t("rounds.Group_Matches")}
                            {round.round === "round_of_16" &&
                              t("rounds.Round_of_16")}
                            {round.round === "quarters" &&
                              t("rounds.Quater_Finals")}
                            {round.round === "golden_boot" &&
                              t("rounds.Golden_Boot")}
                            {round.round === "semifinals" &&
                              t("rounds.Semi_Finals")}
                            {round.round === "finals" && t("rounds.Finals")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })} */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Detail;
