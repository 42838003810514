import React from "react";
import { Link } from "react-router-dom";
import SubHeader from "../../components/SubHeader";
import { useTranslation } from "react-i18next";

const HowToWin = () => {
  const { t } = useTranslation();
  return (
    <>
      <SubHeader title={`${t("menu.How_to_Win")}`} />
      <div className="p-3">
        <div className="bg-white rounded shadow-lg p-4">
          <ul className="list-disc pl-4 space-y-2">
            <li>{t("how_to_Win.line1")}</li>
            <li>{t("how_to_Win.line2")}</li>
            <li>
              {t("how_to_Win.line3")}
              <ul className="list-decimal pl-4 space-y-1">
                <li>{t("how_to_Win.line4")}</li>
                <li>{t("how_to_Win.line5")}</li>
                <li>{t("how_to_Win.line6")}</li>
                <li>{t("how_to_Win.line7")}</li>
                <li>{t("how_to_Win.line8")}</li>
                <li>{t("how_to_Win.line9")}</li>
              </ul>
            </li>
            <li>{t("how_to_Win.line10")}</li>
            <li>{t("how_to_Win.line11")}</li>
          </ul>
          <div className="mt-4">
            <Link to="/" className="btn w-full text-center block">
              {t("common.Back")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToWin;
