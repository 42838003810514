import { IoClose } from "react-icons/io5";
const Modal = ({ setModalShow, children }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-hidden bg-black/70 z-10">
      <div className="max-w-md mx-auto p-6">
        <div className="bg-white p-4 rounded-md relative shadow-2xl">
          <div
            className="absolute -top-2 -right-2 bg-slate-300 rounded-full w-7 h-7 flex items-center justify-center text-lg cursor-pointer"
            onClick={() => setModalShow(false)}
          >
            <IoClose />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
