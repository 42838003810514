import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdShare } from "react-icons/md";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import LogoModal from "../../../components/LogoModal";
import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
import { toast } from "react-toastify";
import { externalLink } from "./../../../helpers/utils";
const Round3 = () => {
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState(null);
  const { userDoctors } = useSelector((state) => state.auth);
  const { predictions } = useSelector((state) => state.predictions);
  const { id } = useParams();

  const [allTeams, setAllTeams] = useState([]);

  useEffect(() => {
    if (!predictions.length) {
      navigate("/");
    }
  }, [navigate, predictions.length]);
  useEffect(() => {
    if (userDoctors?.length > 0 && id) {
      let newDoc = userDoctors.find(
        (d) => d.doctor_id.toString() === id.toString()
      );
      setDoc(newDoc);
    } else {
      navigate("/");
    }
  }, [userDoctors, id, navigate]);
  const handleChange = (e) => {
    if (e.target.checked) {
      if (allTeams.length < 4) {
        setAllTeams((prev) => [...prev, e.target.value]);
      } else {
        e.target.checked = false;
        toast.error("You can select only 4 teams from given list");
      }
    } else {
      let newArr = allTeams.filter((d) => d !== e.target.value);
      setAllTeams(newArr);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setDocPredictions({
        round: predictions[2].round,
        doctor_id: doc.doctor_id,
        countries: allTeams,
      })
    );
    setModalShow(true);
    audioSuccess.play();
  };

  const shareData = {
    title: "Sun Fifa Fever",
    text: `Sun Fifa Fever :${predictions[2]?.name}`,
    url: `${externalLink()}/link/${predictions[2]?.round}/${doc?.doctor_id}`,
  };

  const shareLink = async () => {
    console.log(shareData.url);
    try {
      await navigator.share(shareData);
      alert("Link shared successfully");
    } catch (err) {
      console.log(`Error: ${err}`);
      alert(`Your browser is not supported the share feature`);
    }
  };

  return (
    <>
      {doc && (
        <>
          <SubHeader title={`Dr. ${doc.name}`} />
          <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[2].name} :
              </span>{" "}
              {predictions[2].description}
            </div>
            <div>
              <button
                className="btn !py-1 !px-2 !rounded flex gap-2 items-center !bg-red-600"
                onClick={shareLink}
              >
                <MdShare />
                Share link with doctor
              </button>
            </div>
            {(predictions[2].is_predicted || predictions[2].is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[2].is_predicted &&
                    "You have already predicted this round."}
                  {predictions[2].is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
          <div className="mx-4 flex flex-col bg-white rounded-md overflow-hidden shadow">
            {predictions[2].teams.length > 0 &&
              predictions[2].teams.map((team, index) => {
                return (
                  <label
                    key={index}
                    className={`px-6 py-2 w-full block shadow border-b border-slate-100 ${
                      team.admin_selected
                        ? "bg-green-300 drop-shadow-lg"
                        : "bg-white"
                    }`}
                    data-id={team.country_code}
                    htmlFor={team.country_code}
                  >
                    <div className=" flex items-center gap-4">
                      {predictions[2].is_predicted ||
                      predictions[2].is_locked ? (
                        <input
                          type="checkbox"
                          id={team.country_code}
                          value={team.country_code}
                          name={team.country_code}
                          onChange={(e) => handleChange(e)}
                          checked={team.selected}
                          disabled
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id={team.country_code}
                          value={team.country_code}
                          name={team.country_code}
                          onChange={(e) => handleChange(e)}
                        />
                      )}

                      <img
                        src={`../../flags/${team.country.replace(
                          / /g,
                          ""
                        )}.png`}
                        alt={team.country}
                        className="w-12"
                      />
                      <p>{team.country}</p>
                    </div>
                  </label>
                );
              })}
          </div>
          {!predictions[2].is_predicted && !predictions[2].is_locked && (
            <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
              <div className="max-w-md w-full mx-auto relative px-3 py-2">
                <button
                  type="button"
                  className="btn w-full text-center"
                  disabled={allTeams.length === 4 ? false : true}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {logoShow && (
        <LogoModal setLogoShow={setLogoShow}>
          <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[2]?.name} :
              </span>{" "}
              {predictions[2]?.description}
            </div>
            {(predictions[2]?.is_predicted || predictions[2]?.is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[2]?.is_predicted &&
                    "You have already predicted this round."}
                  {predictions[2]?.is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
        </LogoModal>
      )}

      {modalShow && (
        <AnimationModal setModalShow={setModalShow} routerPath={-1}>
          <SuccessfulSubmit
            title={`${predictions[2].name} prediction submitted successfully!`}
          />
        </AnimationModal>
      )}
    </>
  );
};

export default Round3;
