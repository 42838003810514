import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import layoutSlice from "./features/layout/layoutSlice";
import predictionsSlice from "./features/predictions/predictionsSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    predictions: predictionsSlice,
    layout: layoutSlice,
  },
});
