import React from "react";
import SUCCESSGIF from "./../images/submit-successful.gif";

const SuccessfulSubmit = ({ title }) => {
  return (
    <div className="h-full">
      <img src={SUCCESSGIF} alt="success" className="w-full" />
      <div className="font-bold text-center text-xl text-slate-700">
        {title}
      </div>
    </div>
  );
};

export default SuccessfulSubmit;
