// export const externalLink = "https://devsunfifafever.sunkonnect.in";
// export const externalLink = "https://fifa.solmc.in";
// export const externalLink = "http://192.168.167.147:3000";

export const externalLink = () => {
  const currentUrl = window.location.href;
  let currentEnv = "";
  if (currentUrl.includes("devsunfifafever")) {
    currentEnv = process.env.REACT_APP_DEV_CLIENT_URL;
  } else if (currentUrl.includes("sunfifafever")) {
    currentEnv = process.env.REACT_APP_PROD_CLIENT_URL;
  } else {
    currentEnv = process.env.REACT_APP_DEV_CLIENT_URL;
  }
  return currentEnv;
};
export const getBaseURL = () => {
  const currentUrl = window.location.href;
  let currentEnv = "";
  if (currentUrl.includes("devsunfifafever")) {
    currentEnv = process.env.REACT_APP_DEV_API_URL;
  } else if (currentUrl.includes("sunfifafever")) {
    currentEnv = process.env.REACT_APP_PROD_API_URL;
  } else {
    currentEnv = process.env.REACT_APP_DEV_API_URL;
  }
  return currentEnv;
};
// console.log(getBaseURL());
