import React from "react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

const LogoModal = ({ setLogoShow, countryLogo, children }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-hidden bg-black/70 z-10">
      <div className="max-w-md mx-auto p-6">
        <div className="bg-white p-4 rounded-md relative shadow-2xl">
          <div
            className="absolute -top-2 -right-2 bg-slate-300 rounded-full w-7 h-7 flex items-center justify-center text-lg cursor-pointer"
            onClick={() => setLogoShow(false)}
          >
            <IoClose />
          </div>
          {user ? (
            <img
              src={`../../${user.countryLogo} `}
              alt="fifaimg"
              className="w-full"
            />
          ) : countryLogo ? (
            <img
              src={`../../${countryLogo} `}
              alt="fifaimg"
              className="w-full"
            />
          ) : (
            ""
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default LogoModal;
