import { useEffect, useState, useCallback } from "react";
import { Captcha, useCaptcha } from "react-custom-captcha";
import FIFALOGO from "./../../images/fifa-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import userCountries from "../../helpers/userCountries";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import Terms from "../../components/Terms";
import { clearState, setLogin } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { MdRefresh } from "react-icons/md";

const Login = () => {
  const { validate, refresh } = useCaptcha();
  const [captcha, setCaptcha] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess, isFailed, message, loading, language } = useSelector(
    (state) => state.auth
  );
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslation();
  const onRefreshHandler = useCallback(() => {
    refresh();
  }, [refresh]);
  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string()
      .email(`${t("login.Email_is_required")}`)
      .required(`${t("login.Email_is_required")}`),
    empCode: Yup.string()
      .required(`${t("login.Employee_code_is_required")}`)
      .matches(
        /[A-Za-z0-9]+$/,
        "Only alphabets and number are allowed for this field"
      ),
    country: Yup.string().required(`${t("login.Country_is_required")}`),
    password: Yup.string()
      .required(`${t("login.Password_is_required")}`)
      .test(checkPass),
    terms: Yup.bool().oneOf(
      [true],
      "You must accept the terms & condition before sign in."
    ),
  });

  function checkPass() {
    const { parent } = this;
    let pass = userCountries.find((item) => item.code === parent.country);
    if (pass) {
      if (pass.password === parent.password) {
        return true;
      } else {
        return false;
      }
    }
  }
  useEffect(() => {
    if (!language) {
      navigate("/select-language");
    }
  }, [language, navigate]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      toast.success("Login Successfully");
    }
    if (isFailed) {
      toast.error(message);
    }
    return () => {
      dispatch(clearState());
    };
  }, [message, isFailed, isSuccess, dispatch, navigate]);

  useEffect(() => {
    if (user) {
      dispatch(clearState());
    }
  }, [user, dispatch]);

  // console.log(language);

  return (
    <>
      <img src={FIFALOGO} alt="fifa logo" className="w-36" />
      <div className="bg-white rounded-md p-4 shadow-2xl w-full">
        {!user && (
          <div>
            <Formik
              initialValues={{
                email: "",
                empCode: "",
                password: "",
                terms: false,
              }}
              validationSchema={validationSchemaLogin}
              onSubmit={(val) => {
                const isValidCaptcha = validate(captcha);
                if (isValidCaptcha) {
                  setCaptcha("");
                  const loginData = {
                    email: val.email,
                    empCode: val.empCode,
                    password: val.password,
                    country: val.country,
                  };
                  dispatch(setLogin(loginData));
                } else {
                  toast.error("Please enter valid captcha");
                }
              }}
            >
              {({
                handleBlur,
                handleSubmit,
                errors,
                isValid,
                dirty,
                handleChange,
              }) => (
                <>
                  <Form className="space-y-3" onSubmit={handleSubmit}>
                    <div className="relative group">
                      <label htmlFor="email" className="form-label">
                        {t("login.email")}
                      </label>
                      <Field
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="hasError"
                      />
                    </div>
                    <div className="relative group">
                      <label htmlFor="empCode" className="form-label">
                        {t("login.empCode")}
                      </label>
                      <Field
                        id="empCode"
                        name="empCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="empCode"
                        component="div"
                        className="hasError"
                      />
                    </div>
                    <div className="relative group">
                      <label htmlFor="country" className="form-label">
                        {t("login.select_country")}
                      </label>
                      <Field
                        as="select"
                        id="country"
                        name="country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        {userCountries.map((item) => {
                          return (
                            <option key={item.code} value={item.code}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="hasError"
                      />
                    </div>
                    <div className="relative group">
                      <label htmlFor="password" className="form-label">
                        {t("login.password")}
                      </label>
                      <Field
                        id="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="hasError"
                      />
                    </div>
                    <div className="captchaContainer">
                      <Captcha />
                      <button onClick={onRefreshHandler}>
                        <MdRefresh />
                      </button>
                    </div>
                    <input
                      type="text"
                      required={true}
                      className="form-control"
                      placeholder="Enter captcha code"
                      value={captcha}
                      onChange={(event) => setCaptcha(event.target.value)}
                    />
                    <div className="relative group">
                      <label className="inline-flex">
                        <Field
                          type="checkbox"
                          id="terms"
                          name="terms"
                          className="rounded border-gray-300 text-theme-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-theme-blue-200 focus:ring-opacity-50 mt-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <p className="ml-2">
                          {t("login.terms1")}{" "}
                          <Link
                            className="text-theme-blue-500 underline"
                            to="#"
                            onClick={() => setModalShow(true)}
                          >
                            {t("login.terms2")}
                          </Link>
                        </p>
                      </label>
                      <ErrorMessage
                        name="terms"
                        component="div"
                        className="hasError"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn w-full"
                      disabled={loading || !(isValid && dirty)}
                    >
                      {loading ? "Loading... " : t("login.login")}
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        )}
      </div>
      {modalShow && (
        <Modal setModalShow={setModalShow}>
          <Terms />
        </Modal>
      )}
    </>
  );
};

export default Login;
