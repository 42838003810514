import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: false,
};
export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleDrawer: (state, action) => {
      state.drawer = action.payload;
    },
  },
});

export const { toggleDrawer } = layoutSlice.actions;

export default layoutSlice.reducer;
