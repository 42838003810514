import FIFALOGO from "./../../images/fifa-logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { setLang } from "../../redux/features/auth/authSlice";
import { useEffect } from "react";
const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
  {
    code: "es",
    name: "Español",
  },
];
const SelectLang = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { user } = useSelector((state) => state.auth);
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const validationSchema = Yup.object().shape({
    language: Yup.string().required("Language is required"),
  });

  useEffect(() => {
    if (user) {
      navigate(from);
    }
  }, [user, navigate, from]);

  const actionSubmit = (langVal) => {
    dispatch(setLang(langVal));
    i18next.changeLanguage(langVal);
    navigate("/login");
  };

  return (
    <>
      <img src={FIFALOGO} alt="fifa logo" className="w-48" />
      <div className="bg-white rounded-md p-4 shadow-xl w-full">
        <Formik
          initialValues={{
            language: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {
            actionSubmit(val.language);
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form className="space-y-3" onSubmit={handleSubmit}>
                <div className="relative group">
                  <label className="form-label" htmlFor="language">
                    Select Language
                  </label>
                  <Field
                    as="select"
                    className="form-control"
                    id="language"
                    name="language"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Select Language</option>
                    {languages.map((lang) => {
                      return (
                        <option key={lang.code} value={lang.code}>
                          {lang.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="language"
                    component="div"
                    className="hasError"
                  />
                </div>
                <div className="relative group">
                  <button
                    type="submit"
                    className="btn w-full"
                    disabled={!(isValid && dirty)}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SelectLang;
