const userCountries = [
  {
    name: "Nepal",
    country: "Nepal",
    code: "NP",
    password: "sompraz",
    logo: "logos/somprazLogo.png",
  },
  {
    name: "Myanmar",
    country: "Myanmar",
    code: "MM",
    password: "sompraz",
    logo: "logos/somprazLogo.png",
  },
  {
    name: "Oman",
    country: "Oman",
    code: "OM",
    password: "raciper",
    logo: "logos/raciperLogo.png",
  },
  {
    name: "Sri Lanka",
    country: "SriLanka",
    code: "LKA",
    password: "sompraz",
    logo: "logos/somprazLogo.png",
  },
  {
    name: "Morocco",
    country: "Morocco",
    code: "MAR",
    password: "raciper",
    logo: "logos/raciperLogo.png",
  },
  {
    name: "Bangladesh",
    country: "Bangladesh",
    code: "BD",
    password: "sompraz",
    logo: "logos/somprazLogo.png",
  },
  {
    name: "Nigeria",
    country: "Nigeria",
    code: "NG",
    password: "brustan",
    logo: "logos/brustanColcibraLogo.png",
  },
  {
    name: "Cameroon",
    country: "Cameroon",
    code: "CMR",
    password: "brustan",
    logo: "logos/brustanVoliniLogo.png",
  },
  {
    name: "Ivory coast",
    country: "Ivorycoast",
    code: "CI",
    password: "brustan",
    logo: "logos/brustanVoliniLogo.png",
  },
  {
    name: "Senegal",
    country: "Senegal",
    code: "SEN",
    password: "brustan",
    logo: "logos/brustanVoliniLogo.png",
  },
  {
    name: "Tanzania",
    country: "Tanzania",
    code: "TZ",
    password: "brustan",
    logo: "logos/brustanVoliniLogo.png",
  },
  {
    name: "Zambia",
    country: "Zambia",
    code: "ZM",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Kenya",
    country: "Kenya",
    code: "KE",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Togo",
    country: "Togo",
    code: "TG",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Benin",
    country: "Benin",
    code: "BJ",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Burkina",
    country: "Burkina",
    code: "BF",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Mali",
    country: "Mali",
    code: "ML",
    password: "brustan",
    logo: "logos/brustanLogo.png",
  },
  {
    name: "Mexico",
    country: "Mexico",
    code: "MEX",
    password: "zapex",
    logo: "logos/zapexLogo.png",
  },
  {
    name: "Peru",
    country: "Peru",
    code: "PE",
    password: "sompraz",
    logo: "logos/somprazLogo.png",
  },
];
export default userCountries;
