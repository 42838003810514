import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ allowedRole }) => {
  let location = useLocation();
  const { user, language } = useSelector((state) => state.auth);
  return user?.role === "employee" && language ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/unauthorized" state={{ from: location }} />
  ) : user && language ? (
    <Navigate to="/login" state={{ from: location }} />
  ) : (
    <Navigate to="/select-language" state={{ from: location }} />
  );
};

export default ProtectedRoute;
