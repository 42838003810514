import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { useSelector } from "react-redux";
import SingleDoctor from "../../components/SingleDoctor";
import SubHeader from "../../components/SubHeader";
import { useTranslation } from "react-i18next";

const Client = () => {
  const { t } = useTranslation();
  const { userDoctors } = useSelector((state) => state.auth);
  const [foundDoctors, setFoundDoctors] = useState(null);
  const [name, setName] = useState("");

  useEffect(() => {
    if (userDoctors) {
      setFoundDoctors(userDoctors);
    }
  }, [userDoctors]);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = userDoctors.filter((doc) => {
        return doc.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundDoctors(results);
    } else {
      setFoundDoctors(userDoctors);
    }

    setName(keyword);
  };

  return (
    <>
      <SubHeader title={t("doctor_list.Select_Doctor_from_the_list")} />
      <div className="relative group p-3 bg-white shadow">
        <div className="relative group">
          <MdSearch
            className="text-slate-400 pointer-events-none group-focus-within:text-orange absolute left-3 top-1/2 -mt-2.5"
            aria-hidden="true"
          />
          <input
            type="search"
            value={name}
            onChange={filter}
            placeholder={t("doctor_list.Search_Doctor")}
            className="form-control !pl-10 !text-sm !rounded-none "
          />
        </div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        {foundDoctors === null && "Please wait... your doctor list is loading."}
        {foundDoctors?.length === 0 && "You didn't add a doctor yet."}
        {foundDoctors?.length > 0 &&
          foundDoctors.map((doc) => {
            return <SingleDoctor key={doc.id} {...doc} />;
          })}
        {/* {authLoading
          ? "Please wait... your doctor list is loading."
          : foundDoctors && foundDoctors.length > 0
          ? foundDoctors.map((doc) => {
              return <SingleDoctor key={doc.id} {...doc} />;
            })
          : "You didn't add a doctor yet."} */}
      </div>
      <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
        <div className="max-w-md w-full mx-auto relative px-3 py-2">
          <Link to="/add-doctor" className="btn w-full text-center block">
            {t("doctor_list.Add_a_New_Doctor")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Client;
