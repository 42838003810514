import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import { MdShare } from "react-icons/md";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import LogoModal from "../../../components/LogoModal";
import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
import { externalLink } from "./../../../helpers/utils";
const Round5 = () => {
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState(null);
  const { userDoctors } = useSelector((state) => state.auth);
  const { predictions } = useSelector((state) => state.predictions);
  const { id } = useParams();

  const [team1, setTeam1] = useState("");

  useEffect(() => {
    if (!predictions.length) {
      navigate("/");
    }
  }, [navigate, predictions.length]);
  useEffect(() => {
    if (userDoctors?.length > 0 && id) {
      let newDoc = userDoctors.find(
        (d) => d.doctor_id.toString() === id.toString()
      );
      setDoc(newDoc);
    } else {
      navigate("/");
    }
  }, [userDoctors, id, navigate]);
  const handleChange = (e) => {
    if (e.target.name === "match-1") {
      setTeam1(e.target.value);
    }
  };

  const handleSubmit = () => {
    dispatch(
      setDocPredictions({
        round: predictions[5].round,
        doctor_id: doc.doctor_id,
        countries: [team1],
      })
    );
    setModalShow(true);
    audioSuccess.play();
  };

  const shareData = {
    title: "Sun Fifa Fever",
    text: `Sun Fifa Fever :${predictions[5]?.name}`,
    url: `${externalLink()}/link/${predictions[5]?.round}/${doc?.doctor_id}`,
  };

  const shareLink = async () => {
    console.log(shareData.url);
    try {
      await navigator.share(shareData);
      alert("Link shared successfully");
    } catch (err) {
      console.log(`Error: ${err}`);
      alert(`Your browser is not supported the share feature`);
    }
  };

  return (
    <>
      {doc && (
        <>
          <SubHeader title={`Dr. ${doc.name}`} />
          <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[5].name} :
              </span>{" "}
              {predictions[5].description}
            </div>
            <div>
              <button
                className="btn !py-1 !px-2 !rounded flex gap-2 items-center !bg-red-600"
                onClick={shareLink}
              >
                <MdShare />
                Share link with doctor
              </button>
            </div>
            {(predictions[5].is_predicted || predictions[5].is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[5].is_predicted &&
                    "You have already predicted this round."}
                  {predictions[5].is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
          <div className="pb-2 mx-4 flex flex-col gap-4 mt-4">
            {Object.keys(predictions[5].matches).map((key, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-4 items-center rounded"
                >
                  <h1 className="font-bold">Semi Final - {index + 1}</h1>
                  <div className="flex gap-2 items-center justify-between w-full relative">
                    <div className="bg-orange text-white rounded-full absolute w-8 h-8 z-[1] flex items-center justify-center border shadow-md left-0 right-0 top-0 bottom-0 mx-auto my-auto">
                      vs
                    </div>
                    {predictions[5].matches[key].map((team, i) => {
                      return (
                        <label
                          key={i}
                          htmlFor={team.name}
                          className="customRadio relative w-full shadow-lg"
                        >
                          {team.admin_selected && (
                            <div className="bg-white border border-slate-400 shadow rounded-full w-5 h-5 left-0 right-0 mx-auto -bottom-2 absolute z-[1]">
                              <MdCheckCircle className="w-full h-full  text-green-500 " />
                            </div>
                          )}
                          {predictions[5].is_predicted ||
                          predictions[5].is_locked ? (
                            <input
                              type="radio"
                              id={team.name}
                              name={`match-${index + 1}`}
                              value={team.code}
                              className="absolute -left-[9999px]"
                              onChange={(e) => handleChange(e)}
                              checked={team.selected}
                            />
                          ) : (
                            <input
                              type="radio"
                              id={team.name}
                              name={`match-${index + 1}`}
                              value={team.code}
                              className="absolute -left-[9999px]"
                              onChange={(e) => handleChange(e)}
                            />
                          )}
                          <div className="bg-white rounded pt-9 pb-5  px-4 w-full relative flex items-center justify-center font-bold text-lg">
                            {team.country}
                            <img
                              src={team.image}
                              alt={team.country}
                              className="absolute -top-1/4"
                            />
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {!predictions[5].is_predicted && !predictions[5].is_locked && (
            <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
              <div className="max-w-md w-full mx-auto relative px-3 py-2">
                <button
                  type="button"
                  className="btn w-full text-center"
                  disabled={!team1 ? true : false}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {logoShow && (
        <LogoModal setLogoShow={setLogoShow}>
          <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[5]?.name} :
              </span>{" "}
              {predictions[5]?.description}
            </div>
            {(predictions[5]?.is_predicted || predictions[5]?.is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[5]?.is_predicted &&
                    "You have already predicted this round."}
                  {predictions[5]?.is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
        </LogoModal>
      )}

      {modalShow && (
        <AnimationModal setModalShow={setModalShow} routerPath={-1}>
          <SuccessfulSubmit
            title={`${predictions[5].name} prediction submitted successfully!`}
          />
        </AnimationModal>
      )}
    </>
  );
};

export default Round5;
