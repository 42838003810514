import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdShare } from "react-icons/md";
import SubHeader from "../../../components/SubHeader";
import AnimationModal from "../../../components/AnimationModal";
import SuccessfulSubmit from "../../../animationEvents/SuccessfulSubmit";
import SUCCESSAUDIO from "./../../../sounds/success.mp3";
import { setDocPredictions } from "../../../redux/features/predictions/predictionsSlice";
import { toast } from "react-toastify";
import LogoModal from "../../../components/LogoModal";
import { externalLink } from "./../../../helpers/utils";
const Round1 = () => {
  const audioSuccess = new Audio(SUCCESSAUDIO);
  const [modalShow, setModalShow] = useState(false);
  const [logoShow, setLogoShow] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState(null);
  const { userDoctors } = useSelector((state) => state.auth);
  const { predictions } = useSelector((state) => state.predictions);
  const { id } = useParams();
  const [allTeams, setAllTeams] = useState([]);

  useEffect(() => {
    if (!predictions.length) {
      navigate("/");
    }
  }, [navigate, predictions.length]);

  useEffect(() => {
    if (userDoctors?.length > 0 && id) {
      let newDoc = userDoctors.find(
        (d) => d.doctor_id.toString() === id.toString()
      );
      setDoc(newDoc);
    } else {
      navigate("/");
    }
  }, [userDoctors, id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setDocPredictions({
        round: predictions[0].round,
        doctor_id: doc.doctor_id,
        countries: allTeams,
      })
    );
    setModalShow(true);
    audioSuccess.play();
  };
  const shareData = {
    title: "Sun Fifa Fever",
    text: `Sun Fifa Fever :${predictions[0]?.name}`,
    url: `${externalLink()}/link/${predictions[0]?.round}/${doc?.doctor_id}`,
  };

  const shareLink = async () => {
    console.log(shareData.url);
    try {
      await navigator.share(shareData);
      alert("Link shared successfully");
    } catch (err) {
      console.log(`Error: ${err}`);
      alert(`Your browser is not supported the share feature`);
    }
  };

  return (
    <>
      {doc && (
        <>
          <SubHeader title={`Dr. ${doc.name}`} />
          <div className="px-3 py-2 font-medium bg-white mb-2 shadow-lg text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[0].name} :
              </span>{" "}
              {predictions[0].description}
            </div>
            <div>
              <button
                className="btn !py-1 !px-2 !rounded flex gap-2 items-center !bg-red-600"
                onClick={shareLink}
              >
                <MdShare />
                Share link with doctor
              </button>
            </div>
            {(predictions[0].is_predicted || predictions[0].is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[0].is_predicted &&
                    "You have already predicted this round."}
                  {predictions[0].is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit} className="mx-4 flex flex-col gap-2">
            {predictions[0].teams.length > 0 &&
              predictions[0].teams.map((team, index) => {
                return (
                  <SingleGroup
                    key={index}
                    data={team}
                    setAllTeams={setAllTeams}
                    allTeams={allTeams}
                  />
                );
              })}
          </form>
          {!predictions[0].is_predicted && !predictions[0].is_locked && (
            <div className="w-full bg-black/25 text-white flex justify-center items-center shadow-2xl fixed bottom-0 left-0 z-[5]">
              <div className="max-w-md w-full mx-auto relative px-3 py-2">
                <button
                  type="button"
                  className="btn w-full text-center"
                  disabled={allTeams.length === 16 ? false : true}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {modalShow && (
        <AnimationModal setModalShow={setModalShow} routerPath={-1}>
          <SuccessfulSubmit
            title={`${predictions[0].name} prediction submitted successfully!`}
          />
        </AnimationModal>
      )}
      {logoShow && (
        <LogoModal setLogoShow={setLogoShow}>
          <div className="px-3 py-2 font-medium bg-white mb-2 text-sm">
            <div>
              <span className="font-bold text-base">
                {predictions[0]?.name} :
              </span>{" "}
              {predictions[0]?.description}
            </div>
            {(predictions[0]?.is_predicted || predictions[0]?.is_locked) && (
              <div className="px-3 py-2 font-medium bg-green-600 text-white mb-2 shadow-lg text-sm text-center mt-2">
                <span className="font-bold text-base">
                  {predictions[0]?.is_predicted &&
                    "You have already predicted this round."}
                  {predictions[0]?.is_locked && "This round has been closed."}
                </span>
              </div>
            )}
          </div>
        </LogoModal>
      )}
    </>
  );
};

export default Round1;

const SingleGroup = ({ data, setAllTeams, allTeams }) => {
  const { predictions } = useSelector((state) => state.predictions);
  const [values, setValues] = useState([]);
  const handleChange = (e) => {
    if (e.target.checked) {
      if (values.length < 2) {
        setValues((prev) => [...prev, e.target.value]);
        setAllTeams((prev) => [...prev, e.target.value]);
      } else {
        e.target.checked = false;
        toast.error("You can select only 2 teams from each group.");
      }
    } else {
      let newArr = values.filter((d) => d !== e.target.value);
      setValues(newArr);
      let rm = allTeams.filter((item) => item !== e.target.value);
      setAllTeams(rm);
    }
  };
  return (
    <div className="bg-white rounded-md overflow-hidden shadow">
      <h4 className="py-2 px-4 border-b font-medium leading-4 text-sm">
        Group: {data.group}
      </h4>
      {data.teams.map((team, index) => {
        return (
          <label
            key={index}
            className={`px-6 py-2 w-full block  border-b border-slate-100 ${
              team.admin_selected ? "bg-green-300 drop-shadow-lg" : "bg-white"
            }`}
            htmlFor={team.name}
          >
            <div className=" flex items-center gap-4">
              {predictions[0].is_predicted || predictions[0].is_locked ? (
                <input
                  type="checkbox"
                  id={team.country_code}
                  value={team.country_code}
                  name={team.country_code}
                  onChange={(e) => handleChange(e)}
                  checked={team.selected}
                  disabled
                />
              ) : (
                <input
                  type="checkbox"
                  id={team.country_code}
                  value={team.country_code}
                  name={team.country_code}
                  onChange={(e) => handleChange(e)}
                />
              )}

              <img
                src={`../../flags/${team.country.replace(/ /g, "")}.png`}
                alt={team.country}
                className="w-12"
              />
              <p>{team.country}</p>
            </div>
          </label>
        );
      })}
    </div>
  );
};
