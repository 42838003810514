import React from "react";
import { useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader";
import { useTranslation } from "react-i18next";

const Leaderboard = () => {
  const { t } = useTranslation();
  const { top10 } = useSelector((state) => state.predictions);
  // console.log(top10);
  return (
    <div>
      <SubHeader title={`${t("menu.Leaderboard")} : Top 10`} />
      <div className="p-3 flex flex-col gap-3">
        {top10.length > 0 ? (
          top10.map((item, index) => {
            return (
              <div key={item.id} className="relative pl-10">
                <div className="flex flex-col items-start bg-white shadow-lg rounded p-3">
                  <h4 className="font-medium text-lg mb-1">Dr. {item.name}</h4>
                  <div className="bg-green-700 text-sm text-white py-1 px-2 rounded">
                    Points: {item.points}
                  </div>
                </div>
                <div className="absolute font-bold text-slate-700 bg-white shadow rounded w-8 h-8 top-0 left-0 flex items-center justify-center">
                  {index + 1}
                </div>
              </div>
            );
          })
        ) : (
          <div className="bg-white p-4">No Doctor found</div>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
