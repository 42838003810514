import React from "react";
import { MdMenu, MdHome } from "react-icons/md";
// import FIFALOGO from "../images/fifa-logo.png";
import FIFALOGOWHITE from "../images/fifa-logo.png";
import { useDispatch, useSelector } from "react-redux";
// import { setLogout } from "../redux/features/auth/authSlice";
import { toggleDrawer } from "../redux/features/layout/layoutSlice";
import { Link } from "react-router-dom";
const ClientHeader = () => {
  const dispatch = useDispatch();
  const { drawer } = useSelector((state) => state.layout);
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-full bg-white px-3 py-2 text-slate-800 flex justify-between items-center shadow-2xl sticky top-0 z-[10]">
      <div className="flex gap-4 items-center">
        <MdMenu
          className="text-2xl"
          onClick={() => dispatch(toggleDrawer(!drawer))}
        />
        <Link to="/">
          <img src={FIFALOGOWHITE} alt="fifa" className="w-10" />
        </Link>
        {user && (
          <img src={`${user.countryLogo}`} alt="fifaimg" className="w-36" />
        )}
      </div>
      <Link to="/">
        <MdHome className="text-2xl" />
      </Link>
    </div>
  );
};

export default ClientHeader;
