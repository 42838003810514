import React, { useEffect } from "react";
import SubHeader from "../../components/SubHeader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  createDoctor,
  clearState,
} from "./../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const AddDoctor = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isSuccess, isFailed, message } = useSelector(
    (state) => state.auth
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t("add_a_new_doctor.Name_is_required")}`)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field"),
    email: Yup.string()
      .email()
      .required(`${t("add_a_new_doctor.Email_ID_is_required")}`),
    doctor_code: Yup.string()
      .required(`${t("add_a_new_doctor.Metis_Code_is_required")}`)
      .matches(
        /[A-Za-z0-9]+$/,
        "Only alphabets and number are allowed for this field"
      ),
  });

  const actionSubmit = (docData) => {
    dispatch(createDoctor(docData));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      navigate("/");
    }
    if (isFailed) {
      toast.error(message);
    }
    dispatch(clearState());
  }, [loading, isSuccess, isFailed, message, dispatch, navigate]);
  return (
    <>
      <SubHeader title={`${t("doctor_list.Add_a_New_Doctor")}`} />
      <div className="p-3">
        <Formik
          initialValues={{
            name: "",
            email: "",
            doctor_code: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(val) => {
            const docData = {
              name: val.name,
              email: val.email,
              doctor_code: val.doctor_code,
            };
            actionSubmit(docData);
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            errors,
            isValid,
            dirty,
            handleChange,
          }) => (
            <>
              <Form
                className="bg-white p-6 rounded shadow-lg space-y-3"
                onSubmit={handleSubmit}
              >
                <div className="relative group">
                  <label htmlFor="name" className="form-label">
                    {t("add_a_new_doctor.Full_Name")}
                  </label>
                  <Field
                    id="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="hasError"
                  />
                </div>
                <div className="relative group">
                  <label htmlFor="email" className="form-label">
                    {t("add_a_new_doctor.Email_ID")}
                  </label>
                  <Field
                    id="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="hasError"
                  />
                </div>
                <div className="relative group">
                  <label htmlFor="doctor_code" className="form-label">
                    {t("add_a_new_doctor.Metis_code")}
                  </label>
                  <Field
                    id="doctor_code"
                    name="doctor_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="doctor_code"
                    component="div"
                    className="hasError"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-full rounded-lg"
                  disabled={!(isValid && dirty)}
                >
                  {t("common.Submit")}
                </button>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddDoctor;
