import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cookies from "js-cookie";
import userCountries from "./../../../helpers/userCountries";
import * as API from "../../api";

const initialState = {
  user: JSON.parse(localStorage.getItem("fifaAuth")) || null,
  userDoctors: null,
  loading: false,
  isSuccess: false,
  isFailed: false,
  message: "",
  language: cookies.get("i18next") || "",
};

export const setLogin = createAsyncThunk(
  "auth/setLogin",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await API.setLogin(loginData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDoctors = createAsyncThunk(
  "auth/getDoctors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.getDoctors();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createDoctor = createAsyncThunk(
  "auth/createDoctor",
  async (doctorData, { rejectWithValue }) => {
    try {
      const response = await API.createDoctor(doctorData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFailed = false;
      state.isSuccess = false;
      state.message = "";
      state.loading = false;
    },
    setLang: (state, action) => {
      state.language = action.payload;
    },
    setLogout: (state) => {
      localStorage.removeItem("fifaAuth");
      state.user = null;
      state.userDoctors = null;
    },
  },
  extraReducers: {
    [setLogin.pending]: (state) => {
      state.loading = true;
    },
    [setLogin.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        let countryLogo = "";
        if (action.payload.data.role === "employee") {
          countryLogo = userCountries.find(
            (i) => i.code === action.payload.data.country
          );
        }
        localStorage.setItem(
          "fifaAuth",
          JSON.stringify({
            countryLogo: countryLogo.logo,
            ...action.payload.data,
            token: action.payload.token,
          })
        );
        state.isSuccess = true;
        state.user = { ...action.payload.data, countryLogo: countryLogo.logo };
        state.message = action.payload.message;
      }
      if (action.payload.status === 400) {
        state.isFailed = true;
        state.message = action.payload.message;
      }
    },
    [setLogin.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
    [getDoctors.pending]: (state) => {
      state.loading = true;
    },
    [getDoctors.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.userDoctors = action.payload.data;
        state.message = "Doctors loaded successfully";
      }
      if (action.payload.status === 400) {
        state.isFailed = true;
        state.message = action.payload.message;
      }
    },
    [getDoctors.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },

    [createDoctor.pending]: (state) => {
      state.loading = true;
    },
    [createDoctor.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === 200) {
        state.isSuccess = true;
        state.userDoctors = [action.payload.data, ...state.userDoctors];
        state.message = "Doctor Registered Successfully";
      }
      if (action.payload.status === 400) {
        state.isFailed = true;
        state.message = action.payload.message;
      }
    },
    [createDoctor.rejected]: (state, action) => {
      state.loading = false;
      state.isFailed = true;
      state.message = action.payload.message;
    },
  },
});

export const { setLang, setLogout, clearState } = authSlice.actions;

export default authSlice.reducer;
