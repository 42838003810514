import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../redux/features/layout/layoutSlice";
import { useNavigate } from "react-router-dom";
import {
  MdHome,
  MdLeaderboard,
  MdAddModerator,
  MdLogout,
} from "react-icons/md";
import { BsFillTrophyFill } from "react-icons/bs";
import { setLogout } from "../redux/features/auth/authSlice";
import FIFALOGO from "./../images/fifa-logo.png";
import { useTranslation } from "react-i18next";
// import FIFAMAIN from "./../images/drawerImage.png";

const Drawer = ({ drawer }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`bg-white z-[12] w-64 h-full fixed left-0 top-0 overflow-y-auto flex flex-col drop-shadow-2xl transition-all ease-in-out duration-300 ${
          drawer ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        <ul>
          <li
            className="text-slate-800 bg-orange-50/30 font-bold text-[22px] py-3 px-6"
            style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
          >
            <img src={FIFALOGO} alt="logo-fifa" className="w-1/2" />
          </li>
          <li
            className="text-white bg-orange-400 font-medium py-3 px-6 flex gap-3 items-center"
            style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
          >
            {user.email}
          </li>
          <li
            className="text-slate-800 bg-orange-50/30 font-medium py-3 px-6 flex gap-3 items-center"
            style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
            onClick={() => {
              navigate("");
              dispatch(toggleDrawer(false));
            }}
          >
            <MdHome className="text-xl" /> {t("menu.Home")}
          </li>
          <div className="bg-transparent">
            <li
              className="text-slate-800 bg-orange-50/30 font-medium py-3 px-6 flex gap-3 items-center"
              style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
              onClick={() => {
                navigate("howtowin");
                dispatch(toggleDrawer(false));
              }}
            >
              <BsFillTrophyFill className="text-xl" /> {t("menu.How_to_Win")}
            </li>
            <li
              className="text-slate-800 bg-orange-50/30 font-medium py-3 px-6 flex gap-3 items-center"
              style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
              onClick={() => {
                navigate("leaderboard");
                dispatch(toggleDrawer(false));
              }}
            >
              <MdLeaderboard className="text-xl" /> {t("menu.Leaderboard")}
            </li>
            <li
              className="text-slate-800 bg-orange-50/30 font-medium py-3 px-6 flex gap-3 items-center"
              style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
              onClick={() => {
                navigate("add-doctor");
                dispatch(toggleDrawer(false));
              }}
            >
              <MdAddModerator className="text-xl" /> {t("menu.Add_Doctor")}
            </li>
            <li
              className="text-slate-800 bg-orange-50/30 font-medium py-3 px-6 flex gap-3 items-center"
              style={{ boxShadow: "0 -1px 0 rgb(0 0 0 / 10%) inset" }}
              onClick={() => {
                dispatch(setLogout());
                dispatch(toggleDrawer(false));
              }}
            >
              <MdLogout className="text-xl" /> {t("menu.logout")}
            </li>
          </div>
        </ul>
        <div className="mt-auto flex items-center justify-center">
          <img src={`${user.countryLogo}`} alt="fifaimg" className="w-full" />
        </div>
      </div>
      <div
        className={`bg-black/50 fixed top-0 left-0 w-full h-full transition-opacity ease-in-out duration-300 overflow-x-hidden ${
          drawer
            ? "opacity-100 z-[11] pointer-events-auto"
            : "opacity-0 z-0 pointer-events-none"
        }`}
        onClick={() => dispatch(toggleDrawer(false))}
      ></div>
    </>
  );
};

export default Drawer;
