import axios from "axios";
import moment from "moment/moment";
import { getBaseURL } from "./../helpers/utils";

// console.log(getBaseURL());
const API = axios.create({
  // baseURL: "https://api-sunpharma-fifa.solmc.in",
  baseURL: getBaseURL(),
});
API.interceptors.request.use((req) => {
  if (localStorage.getItem("fifaAuth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("fifaAuth")).token
    }`;
  }
  let todayDates = moment(new Date()).format("YYYYMMDD");
  let yestDates = moment(new Date()).subtract(1, "days").format("YYYYMMDD");
  let tomoDates = moment(new Date()).add(1, "days").format("YYYYMMDD");

  let newConcatString = yestDates + "-" + tomoDates + "-" + todayDates;
  let base64String = window.btoa(newConcatString);
  req.headers["AuthKey"] = base64String;
  return req;
});

export const setLogin = (loginData) => API.post("/employees", loginData);
// for users
export const getDoctors = () => API.get("/employees/doctors");
export const createDoctor = (doctorData) =>
  API.post("/employees/doctors", doctorData);
export const getDocPredictions = (doctor_id) =>
  API.get(`/predictions/${doctor_id}`);
export const getEmpTop10 = (country) =>
  API.get(`/predictions/top_ten_doctors/${country}`);

export const setDocPredictions = (round, doctor_id, countries) =>
  API.post(`/predictions/${round}/${doctor_id}`, { countries });

export const getGoldenBootPlayers = () =>
  API.get(`/predictions/admin/golden_boot_players`);

export const getGoldenBootPrediction = (doctor_id) =>
  API.get(`/predictions/golden_boot/${doctor_id}`);

export const setGoldenBootPrediction = (doctor_id, player_name) =>
  API.post(`/predictions/golden_boot/${doctor_id}`, { player_name });
