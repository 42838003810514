import React, { useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CaptchaProvider } from "react-custom-captcha";
import ClientLayout from "./Layout/ClientLayout";
import AuthLayout from "./Layout/AuthLayout";
import ProtectedRoute from "./Layout/ProtectedRoute";
import Login from "./pages/auth/Login";
import SelectLang from "./pages/auth/SelectLang";
import Client from "./pages/client";
import AddDoctor from "./pages/client/AddDoctor";
import Leaderboard from "./pages/client/Leaderboard";
import Detail from "./pages/client/Detail";
import PageNotFound from "./pages/common/PageNotFound";
import Round1 from "./pages/client/rounds/Round1";
import Round2 from "./pages/client/rounds/Round2";
import Round3 from "./pages/client/rounds/Round3";
import Round4 from "./pages/client/rounds/Round4";
import Round5 from "./pages/client/rounds/Round5";

import Link1 from "./pages/client/rounds/Link1";
import Link2 from "./pages/client/rounds/Link2";
import Link3 from "./pages/client/rounds/Link3";
import Link4 from "./pages/client/rounds/Link4";
import Link5 from "./pages/client/rounds/Link5";

import HowToWin from "./pages/client/HowToWin";
import {
  getDoctors,
  clearState,
  setLogout,
} from "./redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

import { getEmpTop10 } from "./redux/features/predictions/predictionsSlice";
import Round6 from "./pages/client/rounds/Round6";
import Link6 from "./pages/client/rounds/Link6";
import { IdleTimer } from "./components/IdleTimer";

export default function App() {
  // console.log(process.env.REACT_APP_TEXT);
  // console.log(window.location.href);
  const timerRef = useRef(null);
  const { user, isSuccess, isFailed } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>;
  };

  useEffect(() => {
    if (user) {
      dispatch(getDoctors());
    }
    if (user?.role === "employee") {
      dispatch(getEmpTop10(user?.country));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (isSuccess || isFailed) {
      dispatch(clearState());
    }
  }, [isSuccess, isFailed, dispatch]);

  const onIdle = () => {
    if (user) {
      dispatch(setLogout());
    }
  };

  return (
    <>
      <IdleTimer timeout={5000 * 60 * 45} onIdle={onIdle} ref={timerRef}>
        <ScrollToTop>
          <CaptchaProvider>
            <Routes>
              <Route element={<AuthLayout />}>
                <Route path="select-language" element={<SelectLang />} />
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
              <Route path="link/group_matches/:id" element={<Link1 />} />
              <Route path="link/round_of_16/:id" element={<Link2 />} />
              <Route path="link/quarters/:id" element={<Link3 />} />
              <Route path="link/semifinals/:id" element={<Link4 />} />
              <Route path="link/finals/:id" element={<Link5 />} />
              <Route path="link/golden_boot/:id" element={<Link6 />} />

              <Route element={<ProtectedRoute allowedRole={["employee"]} />}>
                <Route element={<ClientLayout />}>
                  <Route path="" element={<Client />} />
                  <Route path="add-doctor" element={<AddDoctor />} />
                  <Route path="howtowin" element={<HowToWin />} />
                  <Route path="leaderboard" element={<Leaderboard />} />
                  <Route path="detail/:id" element={<Detail />} />
                  <Route
                    path="predictions/group_matches/:id"
                    element={<Round1 />}
                  />
                  <Route
                    path="predictions/round_of_16/:id"
                    element={<Round2 />}
                  />
                  <Route path="predictions/quarters/:id" element={<Round3 />} />
                  <Route
                    path="predictions/semifinals/:id"
                    element={<Round4 />}
                  />
                  <Route path="predictions/finals/:id" element={<Round5 />} />
                  <Route
                    path="predictions/golden_boot/:id"
                    element={<Round6 />}
                  />
                </Route>
              </Route>
            </Routes>
          </CaptchaProvider>
        </ScrollToTop>
      </IdleTimer>
    </>
  );
}
