import { useTranslation } from "react-i18next";
const Terms = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="mb-2 font-bold text-xl">{t("login.tc")}</h1>
      <div className="text-sm">
        {t("termsandconditions.line1")}
        {/* Sun FIFA Fever is not affiliated in any way to and claims no
        association, in any capacity whatsoever, with the (i) Fédération
        Internationale de Football Association ("FIFA") or any national football
        association/federation or team, (ii) English Football Association
        ("FA"), the English Premier League or any domestic clubs in the United
        Kindom, (iii) Real Federación Española de Fútbol ("RFEF"), the Liga
        Nacional de Fútbol Profesional or any domestic club in Spain or (iii)
        any other domestic football tournament/league/competition, or tournament
        franchise/team/club (other than where specifically stated). Sun FIFA
        Fever acknowledges that the FIFA, FA, RFEF, and its
        franchises/teams/clubs, respective national football
        associations/federations, domestic tournament/competition organizers,
        and franchises/teams/clubs, respectively, own all proprietary names and
        marks relating to the relevant tournament or competition. */}
        <br></br>
        <br></br>
        {t("termsandconditions.line2")}
        {/* Sun FIFA Fever is an application built just for entertainment and
        engagement purpose. */}
      </div>
    </div>
  );
};

export default Terms;
