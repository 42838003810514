import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../redux/features/auth/authSlice";
const PageNotFound = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(setLogout());
    }
    navigate("/");
  }, [navigate, user, dispatch]);
  return <div>Page Not Found</div>;
};

export default PageNotFound;
