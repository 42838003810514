import { Outlet } from "react-router-dom";
const AuthLayout = () => {
  return (
    <>
      <div className="h-full max-w-md w-full mx-auto p-4 flex flex-col items-center justify-center gap-8">
        <Outlet />
      </div>
    </>
  );
};

export default AuthLayout;
